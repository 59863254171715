// 申遗承诺
const selfData = {
    loading: false,
    snTable: {
        table: {
            data: [],
            rowKey: 'ID',
        },
        columns: {
            // 选择、序号
            columnsOperate: [
                {
                    isShow: true,
                    label: '序号',
                    type: 'index',
                    width: 50
                }
            ],
            // 数据列
            columnsData: [
                {
                    label: "病害名称",
                    prop: "BHMC",
                    minWidth: "",
                    className: 'bhSytle'

                },
                {
                    label: "设备名称",
                    prop: "SBMC",
                    minWidth: "",
                    className: 'sbSytle',
                    minWidth: "150"
                },
                {
                    label: "监测项",
                    prop: "JCX"
                },
                {
                    label: "数值",
                    prop: "VALUE"
                },
                {
                    label: "单位",
                    prop: "DW"
                },
                {
                    label: "采集时间",
                    prop: "CJSJ",
                    width: "180"
                },
                {
                    label: "接收时间",
                    prop: "RKSJ",
                    width: "180"
                },
            ],
            // 操作列
            columnsBtn: {
                buttonData: [{
                    isShow: true,
                    showMethod: 'showSeeBtn',
                    btnType: 'img',
                    operateType: 'showHistoryData',
                    title: '查看历史数据',
                    src: 'working_desk/monitor_data/dataHistory.png',
                    backColor: 'transparent',
                    color: '#20a0ff'
                }]
            }
        },
    },
    snPage: {
        tiaoshu: 20,
        count: 0,
        currentPage: 1,
        operateType: 'page',
        bhTotal: 0
    },
    snCount: {
        eachtotal: [20, 50, 100],
        defaultTiaoshu: 20
    },
    snSearch: {
        inputData: [{
            label: '关键字',
            placeholder: "请输入关键字",
            value: 'keyword',
            operateType: 'search',
            isShow: true
        }],
    },
    snButton: {
        buttonData: []
    }
};
export default selfData;