const commonMethods = {
    spanMethod({ row, column, rowIndex, columnIndex }, data, expandRowData) {
        if (column.property && (row[`${column.property}Num`] || row[`${column.property}Num`] === 0)) {
            if (row[`${column.property}Num`]) {
                return {
                    rowspan: row[`${column.property}Num`],
                    colspan: 1,
                };
            } else {
                return {
                    rowspan: row[`${column.property}Num`],
                    colspan: 0,
                };
            }
        } else {
            return {
                rowspan: 1,
                colspan: 1,
            };
        }
    }
};
export { commonMethods };