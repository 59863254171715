<template>
    <div class="disease_history_data">
        <div class="headBox">
            <div class="btnBox flex-between-y-center">
                <div
                    style="border-radius: 5px 0px 0px 5px;"
                    class="btnItem flex-between-y-center"
                    :class="{ btnItemActive: activeLx == 'staticPhoto' }"
                    @click="changeDisplay('staticPhoto')"
                >
                    统计图
                </div>
                <div
                    style="border-radius: 0px 5px 5px 0px;"
                    class="btnItem flex-between-y-center"
                    :class="{ btnItemActive: activeLx == 'dataList' }"
                    @click="changeDisplay('dataList')"
                >
                    数据列表
                </div>
            </div>
            <div class="info flex-between-y-center">
                <div>病害名称: {{ dialogInfo && dialogInfo.SBMC }}</div>
            </div>
        </div>
        <div class="mainBox">
            <div class="titleBox flex-between-y-center">
                <div class="timeChoise">
                    <div class="timeItem" :class="{ timeItemActive: activeTime == 'oneDay' }" @click="changeTime('oneDay')">
                        最近一天
                    </div>
                    <div class="timeItem" :class="{ timeItemActive: activeTime == 'oneMonth' }" @click="changeTime('oneMonth')">
                        最近一月
                    </div>
                    <div class="timeItem" :class="{ timeItemActive: activeTime == 'threeMonth' }" @click="changeTime('threeMonth')">
                        最近三月
                    </div>
                    <div class="timeItem" :class="{ timeItemActive: activeTime == 'sixMonth' }" @click="changeTime('sixMonth')">
                        最近六月
                    </div>
                    <div class="timeItem" :class="{ timeItemActive: activeTime == 'oneYear' }" @click="changeTime('oneYear')">
                        最近一年
                    </div>
                    <div class="timeItem" :class="{ timeItemActive: activeTime == 'byUser' }" @click="changeTimeByUser('byUser')">
                        自定义
                    </div>
                </div>
                <div class="timeDisplay">
                    <div class="timeItem">{{ startTime }}</div>
                    <div class="separate">-</div>
                    <div class="timeItem">{{ endTime }}</div>
                </div>
                <div class="operateBtn" v-if="activeLx == 'dataList'" @click="exportData">数据导出</div>
            </div>
            <div v-show="activeLx == 'staticPhoto'">
                <div v-if="echartData && echartData.length===0" class="nodata flex-between-y-center">
                    暂无数据
                </div>
                <div v-show="echartData && echartData.length" id="echartBox"></div>
            </div>
            <div class="contentBox commonTable" v-show="activeLx == 'dataList'">
                <div v-if="loading" class="load">
                    <i class="el-icon-loading"></i>
                </div>
                <el-table v-if="!loading" :data="historyData" max-height="400" style="width: 100%">
                    <el-table-column type="index" width="50" label="序号" align="center"></el-table-column>
                    <el-table-column prop="SBMC" label="监测点位" align="center"></el-table-column>
                    <el-table-column prop="CJSJ" label="采集时间" align="center"></el-table-column>
                    <el-table-column prop="VALUE" align="center">
                        <template slot="header" slot-scope="scope">
                            监测数值<span v-if="historyData&&historyData.length">({{historyData[0].DW}})</span>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination
                    v-if="!loading"
                    class="pageBox"
                    @size-change="handleSizeChange"
                    @current-change="handleCurrentChange"
                    :current-page="currentPage"
                    :page-sizes="[10, 20, 50, 100]"
                    :page-size="pageSize"
                    layout="total, sizes, prev, pager, next"
                    :total="historyTotol">
                </el-pagination>
            </div>
        </div>
        <sn-dialog :appendToBody="true" :dialogData.sync="dialogTime">
            <div class="timeItem flex-between-y-center" style="justify-content: start;">
                <div>开始时间:</div>
                <div style="margin-left: 10px">
                    <el-date-picker
                        v-model="startTimeByUser"
                        size="small"
                        type="datetime"
                        placeholder="选择日期时间">
                    </el-date-picker>
                </div>
            </div>
            <div class="timeItem flex-between-y-center" style="justify-content: start;margin: 5px 0;">
                <div>结束时间:</div>
                <div style="margin-left: 10px">
                    <el-date-picker
                        v-model="endTimeByUser"
                        size="small"
                        type="datetime"
                        placeholder="选择日期时间">
                    </el-date-picker>
                </div>
            </div>
            <div class="flex-between-y-center" style="justify-content: center">
                <el-button type="primary" size="small" @click="sureTime">确定</el-button>
                <el-button size="small" @click="closeTime">取消</el-button>
            </div>
        </sn-dialog>
    </div>
</template>
<script>
import { mapActions } from "vuex";
export default {
    name: "disease_history_data",
    data() {
        return {
            activeLx: "staticPhoto",
            startTime: "",
            endTime: "",
            historyData: [],
            historyTotol: 0,
            currentPage: 1,
            pageSize: 10,
            loading: false,
            activeTime: null,
            echartData: [],
            dialogTime: {
                title: "自定义时间",
                dialog: false,
                width: '360px',
                class: 'timeBox'
            },
            startTimeByUser: "",
            endTimeByUser: "",

        };
    },
    components: {},
    computed: {},
    props: {
        dialogInfo: Object,
        currentItem: [String, Number]
    },
    watch: {},
    methods: {
        ...mapActions([
            "GetBhjcsjHistoryData",
            "ExportBhjcsjdData",
            "GetMonitorDataAvgByDaily"
        ]),
        changeTime(key) {
            this.activeTime = key;
            this.endTime = this.common.getTimeYmdhms();
            let dt = new Date();
            let startTime = null;
            if (key == "oneDay") {
                startTime = dt.setDate(dt.getDate() - 1);
            } else if (key == "oneMonth") {
                startTime = dt.setMonth(dt.getMonth() - 1);
            } else if (key == "threeMonth") {
                startTime = dt.setMonth(dt.getMonth() - 3);
            } else if (key == "sixMonth") {
                startTime = dt.setMonth(dt.getMonth() - 6);
            } else if (key == "oneYear") {
                startTime = dt.setMonth(dt.getMonth() - 12);
            } else if (key == "byUser") {
            }
            this.startTime = this.common.getTimeYmdhms(startTime);
            if (this.activeLx == 'staticPhoto') {
                this.getEchartData();
            } else if (this.activeLx == 'dataList') {
                this.getHistoryData();
            }
        },
        // 获得历史数据
        async getHistoryData() {
            this.historyData = [];
            this.historyTotol = 0;
            this.loading = true;
            let res = await this.GetBhjcsjHistoryData({
                jcxCode: this.currentItem,
                paraid: this.dialogInfo.PARAID,
                startTime: this.startTime,
                endTime: this.endTime,
                pageIndex: this.currentPage,
                pageSize: this.pageSize
            })
            this.loading = false;
            if (res.ISSUCCESS) {
                let data = res.RESULTVALUE;
                this.historyData = data.RESULT;
                this.historyTotol = data.COUNT;
            }
        },
        // 获得echart数据
        async getEchartData() {
            this.echartData = [];
            let res = await this.GetMonitorDataAvgByDaily({
                jcxCode: this.currentItem,
                paraid: this.dialogInfo.PARAID,
                startTime: this.startTime,
                endTime: this.endTime
            })
            if (res.ISSUCCESS) {
                this.echartData = res.RESULTVALUE;
                if (this.echartData.length) {
                    this.$nextTick(() => {
                        this.initEchart();
                    })
                } else {
                }
            }
        },
        initEchart() {
            let valueList = [], dateList = [];
            this.echartData.forEach(v => {
                valueList.push(v.VALUE);
                dateList.push(v.CJSJ);
            })
            let dw = this.echartData[0] && this.echartData[0].DW;
            var myChart = this.$echarts.dispose(document.getElementById('echartBox'));
                myChart = this.$echarts.init(document.getElementById('echartBox'));
            var option = {
                color: '#7eb6ed',
                title: {
                    text: this.dialogInfo && this.dialogInfo.SBMC,
                    x: 'center'
                },
                toolbox: {
                    feature: {
                        saveAsImage: {}
                    }
                },
                tooltip: {
                    trigger: 'axis',
                    formatter: `{b0}: {c0}${dw}`
                },
                xAxis: {
                    type: 'category',
                    data: dateList
                },
                yAxis: {
                    type: 'value',
                    name: dw? `单位: ${dw}`: ''
                },
                series: [{
                    data: valueList,
                    type: 'line'
                }]
            };
            myChart.setOption(option);
        },
        // 导出数据
        exportData() {
            let url =
                window.REQUEST_URL + `MonitorData/ExportBhjcsjdData?jcxCode=${this.currentItem}&jwtToken=${localStorage.accessToken}&paraid=${this.dialogInfo.PARAID}`;
            $.ajax({
                headers: {
                    "jwtToken": localStorage.accessToken, // 此处放置请求到的用户token
                },
                type: "GET",
                url: url, // 请求url
                xhrFields: { responseType: "arraybuffer" },
                success: (data, status, xhr) => {
                    let blob = new Blob([data], { type: "application/vnd.ms-excel" });
                    let name = '历史监测数据.xls';
                    let objectUrl = URL.createObjectURL(blob);
                    var disposition = xhr.getResponseHeader('Content-Disposition');
                    if (disposition) {
                        name = this.getFileName(disposition)
                    }
                    let a = document.createElement('a');
                    document.body.appendChild(a);
                    a.setAttribute('style', 'display:none');
                    a.setAttribute('href', objectUrl);
                    a.setAttribute('download', name);
                    a.click();
                    URL.revokeObjectURL(objectUrl);
                },
                error: (err) => {
                    console.log(err, '导出失败');
                }
            });
        },
        // 从浏览器属性里读取文件名
        getFileName(filename) {
            let tempstr = filename && filename.split('filename*=') && filename.split('filename*=')[1];
            let name = tempstr && tempstr.split('\'\'') && tempstr.split('\'\'')[1];
            return decodeURI(name);
        },
        // 自定义时间
        changeTimeByUser(key) {
            this.activeTime = key;
            this.dialogTime.dialog = true;
            this.startTimeByUser = this.startTime;
            this.endTimeByUser = this.endTime;
        },
        closeTime() {
            this.dialogTime.dialog = false;
        },
        sureTime() {
            var startTime = new Date(this.startTimeByUser);
            var endTime = new Date(this.endTimeByUser);
            if ((endTime.getTime() - startTime.getTime())<0) {
                this.$message({
                    type: 'warning',
                    message: '开始时间不能大于结束时间'
                })
            } else {
                this.startTime = this.common.getTimeYmdhms(this.startTimeByUser);
                this.endTime = this.common.getTimeYmdhms(this.endTimeByUser);
                this.getHistoryData();
                this.getEchartData();
                this.dialogTime.dialog = false;
            }
        },
        handleSizeChange(val) {
            this.pageSize = val;
            this.getHistoryData();
        },
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getHistoryData();
        },
        changeDisplay(key) {
            this.activeLx = key;
            if (this.activeLx == 'staticPhoto') {
                this.getEchartData();
            } else if (this.activeLx == 'dataList') {
                this.getHistoryData();
            }
        }
    },
    mounted() {
        this.changeTime("oneMonth");
    },
};
</script>
<style scoped lang="scss">
.disease_history_data {
    .headBox {
        display: flex;
        justify-content: flex-start;
        height: 60px;
        align-items: 60px;
        border-bottom: 2px solid #e5e5e5;
        align-items: center;
        .btnBox {
            margin-left: 25px;
            width: 150px;
            border-radius: 5px;
            .btnItem {
                flex: 1;
                cursor: pointer;
                background: #e5e5e5;
                height: 30px;
                justify-content: center;
                color: #2d4475;
            }
            .btnItemActive {
                background: #2a63d5;
                color: #ffffff;
            }
        }
        .info {
            margin-left: 20px;
        }
    }
    .mainBox {
        .titleBox {
            height: 45px;
            padding: 0 25px;
            justify-content: flex-start;
            .timeChoise {
                display: flex;
                justify-content: flex-start;
                .timeItem {
                    width: 80px;
                    height: 25px;
                    border: 1px solid #2a63d5;
                    border-radius: 5px;
                    color: #2a63d5;
                    text-align: center;
                    cursor: pointer;
                }
                .timeItemActive{
                    background: #2a63d5;
                    color: #fff;
                }
                .timeItem + .timeItem {
                    margin-left: 10px;
                }
            }
            .timeDisplay {
                display: flex;
                margin-left: 10px;
                .timeItem{
                    width: 160px;
                    text-align: center;
                    height: 25px;
                    background: #eeeeee;
                    border: 1px solid #e5e5e5;
                    border-radius: 5px;
                }
                .separate{
                    padding: 0 5px;
                    width: 20px;
                    text-align: center;
                }
            }
            .operateBtn{
                width: 85px;
                line-height: 25px;
                background: #ffcc66;
                border-radius: 5px;
                color: #fff;
                text-align: center;
                margin-left: 5px;
                cursor: pointer;
            }
        }
        #echartBox{
            margin: 10px 25px;
            height: 400px;
        }
        .contentBox {
            padding: 10px 25px;
            .load{
                i{
                    color: red;
                }
                min-height: 180px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            .tableBox{

            }
            .pageBox{
                text-align: center;
                margin-top: 5px;
            }
        }
    }
    .timeBox{
        .timeItem{
            display: flex;
            justify-content: flex-start;
        }
    }
    .nodata{
        min-height: 180px;
        justify-content: center;
    }
}
</style>
