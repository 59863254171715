<template>
    <div>
        <sn-table-group
            :tableGroupAttributes="tableGroupAttributes"
            :commonMethods.sync="commonMethods"
            @handleChange="handleChange"
        ></sn-table-group>
        <sn-dialog :dialogData.sync="dialogDisease">
            <disease-history-data
                v-if="dialogDisease.dialog"
                :dialogInfo="dialogDisease.row"
                :currentItem="currentItem"
                @closeDiseaseDialog="dialogDisease.dialog = false"
            >
            </disease-history-data>
        </sn-dialog>
    </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import selfData from "./sn-table-group.js";
import { commonMethods } from "./table-common-methods";
import DiseaseHistoryData from "@views/working_desk/monitoring_data/comp/disease_history_data";
export default {
    name: "disease_data",
    components: {
        DiseaseHistoryData,
    },
    data() {
        return {
            selfData,
            commonMethods,
            tableGroupAttributes: {},
            dialogDisease: {
                title: "监测数据",
                dialog: false,
                row: "",
                class: "disease_history_data",
                width: "1040px",
            },
            currentItem: "",
            keyword: "",
        };
    },
    watch: {
        $route() {
            this.tableGroupAttributes = this.selfData;
            this.currentItem = this.$route.query.bm;
            this.getData();
        },
        userId() {
            console.log("重新请求" + this.userId + "的数据");
        },
    },
    methods: {
        ...mapActions(["GetBhjcsjList"]),
        handleChange(type, value) {
            console.log(type, value);
            switch (type) {
                case "search":
                    this.keyword = value[0];
                    this.tableGroupAttributes.snPage.currentPage = 1;
                    this.getData();
                    break;
                case "total": // 每页条数
                    this[value[1]] = value[0];
                    this.tableGroupAttributes.snPage.tiaoshu = value[0];
                    this.tableGroupAttributes.snPage.currentPage = 1;
                    this.getData();
                    break;
                case "page": // 当前是第几页
                    this.tableGroupAttributes.snPage.currentPage = value[0];
                    this.getData();
                    break;
                case "showHistoryData": // 病害历史数据
                    this.dialogDisease.dialog = true;
                    this.dialogDisease.row = value[0];
                    this.dialogDisease.title =
                        this.dialogDisease.row && this.dialogDisease.row.SBMC;
                    console.log(this.currentItem, "1212");
                    break;
            }
        },
        async getData() {
            console.log(this.tableGroupAttributes, this.selfData, "1212122");
            this.tableGroupAttributes.snTable.table.data = [];
            this.tableGroupAttributes.snTable.table.loading = true;
            let result = await this.GetBhjcsjList({
                jcxCode: this.currentItem,
                keyword: this.keyword,
                pageIndex: this.tableGroupAttributes.snPage.currentPage,
                pageSize: this.tableGroupAttributes.snPage.tiaoshu,
            });
            if (
                result &&
                result.ResultValue &&
                result.ResultValue.gridData &&
                result.ResultValue.gridData.length
            ) {
                this.getSpanNum(result.ResultValue.gridData, "BHMC");
                this.getSpanNum(result.ResultValue.gridData, "SBMC", "BHMC");
                console.log(result.ResultValue.gridData, "合并之后");
            }
            let da = result.ResultValue;
            this.tableGroupAttributes.snPage.bhTotal = da.bhTotal;
            this.tableGroupAttributes.snTable.table.data = da.gridData;
            this.tableGroupAttributes.snPage.count = Number(da.total) || 0;
            this.tableGroupAttributes.loading = false;
        },
        // 计算合并单元格
        getSpanNum(data, key, frontList) {
            let spanData = [];
            console.log(data, key, frontList, "计算合并单元格");
            // 计算单元格是否需要显示  0 => 不需要显示，它之前有相同元素。 1 => 需要显示
            data.forEach((v, ind) => {
                if (v[key]) {
                    // 第一行 必定需要显示
                    if (ind === 0) {
                        spanData.push({
                            num: 1,
                            value: v[key],
                            BHMC: v.BHMC,
                        });
                    } else {
                        // 当前元素和前一个相同 且前面字段完全一致，不需要显示。否则需要显示
                        if (
                            v[key] == data[ind - 1][key] &&
                            (!frontList ||
                                (frontList &&
                                    this.judgeEqual(
                                        v,
                                        data[ind - 1],
                                        frontList
                                    )))
                        ) {
                            spanData.push({
                                num: 0,
                            });
                        } else {
                            spanData.push({
                                num: 1,
                                value: v[key],
                                BHMC: v.BHMC,
                            });
                        }
                    }
                    // 空值默认不合并
                } else {
                    spanData.push({
                        num: 1,
                        value: v[key],
                        BHMC: v.BHMC,
                    });
                }
            });
            // 需要显示的计算 合并行数
            spanData.forEach((v, ind) => {
                if (v.num && v.value) {
                    for (let i = ind + 1; i < data.length; i++) {
                        // 当前单元格 与 上一个单元格 相同 加一。如果不相同，退出循环
                        if (
                            v.value == data[i][key] &&
                            (!frontList ||
                                (frontList &&
                                    this.judgeEqual(v, data[i], frontList)))
                        ) {
                            v.num++;
                        } else {
                            break;
                        }
                    }
                }
            });
            // 赋值, 用于合并方法。
            data.forEach((v, ind) => {
                v[`${key}Num`] = spanData[ind].num;
            });
        },
        // 判断是否字段相同
        judgeEqual(row1, row2, list) {
            let data = list.split(",");
            let equal = true;
            data.forEach((v) => {
                if (equal) {
                    equal = row1[v] == row2[v];
                }
            });
            return equal;
        },
    },
    mounted() {
        this.tableGroupAttributes = this.selfData;
        this.currentItem = this.$route.query.bm;
        this.getData();
        console.log("121212");
    },
};
</script>
<style></style>
